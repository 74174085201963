<template>
  <div>
    <b-tabs card class="border card">
      <b-tab title="Informações do Cliente">
        <b-row class="card" v-if="client.name">
          <div class="w-100 m-3">
            <b-button variant="success" :to="{ name: 'client-edit', params: { id: client_id } }">Editar cliente</b-button>
          </div>
          <div class="w-100">
            <h5 class="title-card">
              <i class="fas fa-user"></i> Dados Pessoais
            </h5>
          </div>
          <div class="w-100 p-3">
            <b-row>
              <b-col lg="5">
                <b-form-group label="Nome do cliente">
                  <b-form-input v-model="client.name" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="7">
                <b-form-group label="Email">
                  <b-form-input v-model="client.email" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Documento (CPF ou CNPJ)">
                  <b-form-input v-model="client.document" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Celular">
                  <b-form-input v-model="client.phone_mobile" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Localidade">
                  <b-form-input v-model="client.localidade" disabled />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-row>

        <b-row class="card mt-5">
          <div class="w-100">
            <h5 class="title-card">
              <i class="fas fa-home"></i> Endereço
            </h5>
          </div>
          <div class="w-100 p-3">
            <b-row>
              <b-col lg="3">
                <b-form-group label="CEP">
                  <b-form-input v-model="client.addresses[0].zip_code" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="7">
                <b-form-group label="Rua">
                  <b-form-input v-model="client.addresses[0].street" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="2">
                <b-form-group label="Número">
                  <b-form-input v-model="client.addresses[0].number" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="5">
                <b-form-group label="Bairro">
                  <b-form-input v-model="client.addresses[0].neighborhood" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="">
                <b-form-group label="Estado">
                  <b-form-input v-model="client.addresses[0].city.province.name" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="">
                <b-form-group label="Cidade">
                  <b-form-input v-model="client.addresses[0].city.name" disabled />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-tab>
      <b-tab title="Dados para financiamento" active>
        <b-row>
          <b-col lg="12">
            <b-form-checkbox v-model="legalPerson" class="mb-3">Pessoa jurídica</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <span>OBS: Campos com (*) são obrigatórios</span>
          </b-col>
        </b-row>

        <!-- Pessoa física -->
        <b-row class="mt-3" v-if="!legalPerson">
          <b-col lg="4">
            <b-form-group label="Estado civil*">
              <b-form-select :class="{ 'form-group--error': $v.payload.marital_status.$error }"
                v-model="$v.payload.marital_status.$model" :state="$v.payload.marital_status.$dirty ?
                  !$v.payload.marital_status.$error : null" :options="maritalOptions" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Renda mensal*">
              <money class="form-control" :class="{ 'form-group--error': $v.payload.monthly_income.$error }"
                v-model="$v.payload.monthly_income.$model" :state="$v.payload.monthly_income.$dirty ?
                  !$v.payload.monthly_income.$error : null" v-bind="money" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Valor do patrimônio*">
              <money class="form-control" :class="{ 'form-group--error': $v.payload.patrimony_value.$error }"
                v-model="$v.payload.patrimony_value.$model" :state="$v.payload.patrimony_value.$dirty ?
                  !$v.payload.patrimony_value.$error : null" v-bind="money" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Situação do imóvel*">
              <b-form-select :class="{ 'form-group--error': $v.payload.property_situation.$error }"
                v-model="$v.payload.property_situation.$model" :state="$v.payload.property_situation.$dirty ?
                  !$v.payload.property_situation.$error : null" :options="propertySituationOptions" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Data de nascimento*">
              <b-form-input placeholder="dd/mm/aaaa" v-mask="'##/##/####'"
                :class="{ 'form-group--error': $v.payload.birth_date.$error }" v-model="$v.payload.birth_date.$model"
                :state="$v.payload.birth_date.$dirty ?
                  !$v.payload.birth_date.$error : null" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="5">
            <b-form-group label="Profissão*">
              <Autocomplete v-if="payload.profession" style="margin-top: -2px;" :source="professions"
                input-class="form-control" resultsValue="value" placeholder="Pesquisar" @selected="onProfessionChange"
                :initialValue="payload.profession" :initialDisplay="payload.profession" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group label="Natureza da ocupação*">
              <b-form-select :class="{ 'form-group--error': $v.payload.occupation.$error }"
                v-model="$v.payload.occupation.$model" :state="$v.payload.occupation.$dirty ?
                  !$v.payload.occupation.$error : null" :options="ocupationOptions" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Tempo de empresa*">
              <b-input-group append="Anos">
                <b-form-input :class="{ 'form-group--error': $v.payload.company_time.$error }"
                  v-model="$v.payload.company_time.$model" :state="$v.payload.company_time.$dirty ?
                    !$v.payload.company_time.$error : null" type="number" />
              </b-input-group>
              <b-form-invalid-feedback v-show="$v.payload.company_time.$error">
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Telefone comercial">
              <b-form-input :class="{ 'form-group--error': $v.payload.company_phone.$error }"
                v-model="$v.payload.company_phone.$model" v-mask="'(##)####-####'" />
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <div class="border" v-if="payload.bank_statement">
              <img :src="payload.bank_statement" />
            </div>
            <b-form-group label="Extrato bancário, holerite ou declaração de imposto de renda">
              <b-form-file @change="uploadFile" placeholder="Escolha um arquivo ou arraste-o aqui..."
                drop-placeholder="Solte o arquivo aqui..." browse-text="Procurar" />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Carência">
              <b-input-group append="Anos">
                <b-form-input :class="{ 'form-group--error': $v.payload.lack.$error }" v-model="$v.payload.lack.$model"
                  type="number" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Parcelas">
              <b-input-group append="X">
                <b-form-input :class="{ 'form-group--error': $v.payload.installments.$error }"
                  v-model="$v.payload.installments.$model" type="number" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Pessoa juridica -->
        <b-row class="mt-3" v-if="legalPerson">
          <b-col lg="12 mb-3 h4">Dados pessoa juridica</b-col>
          <b-col lg="3">

            <b-form-group label="Cartão CNPJ*">
              <b-form-input :class="{ 'form-group--error': $v.payload.cnpj_card.$error }"
                v-model="$v.payload.cnpj_card.$model" :state="$v.payload.cnpj_card.$dirty ?
                  !$v.payload.cnpj_card.$error : null" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Contrato social atualizado*">
              <b-form-input :class="{ 'form-group--error': $v.payload.social_contract.$error }"
                v-model="$v.payload.social_contract.$model" :state="$v.payload.social_contract.$dirty ?
                  !$v.payload.social_contract.$error : null" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Faturamento*">
              <money class="form-control" :class="{ 'form-group--error': $v.payload.invoicing.$error }"
                v-model="$v.payload.invoicing.$model" :state="$v.payload.invoicing.$dirty ?
                  !$v.payload.invoicing.$error : null" v-bind="money" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Telefone comercial">
              <b-form-input :class="{ 'form-group--error': $v.payload.company_phone.$error }"
                v-model="$v.payload.company_phone.$model" v-mask="'(##)####-####'" />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <div class="border" v-if="payload.installation_location">
              <img :src="payload.installation_location" />
            </div>
            <b-form-group label="Comprovante do local de instalação*">
              <b-form-file @change="uploadFile" placeholder="Escolha um arquivo ou arraste-o aqui..."
                drop-placeholder="Solte o arquivo aqui..." browse-text="Procurar" />
            </b-form-group>
          </b-col>

          <b-col lg="12 my-3 h4">Dados do avalista</b-col>

          <b-col lg="3">
            <b-form-group label="Email*">
              <b-form-input :class="{ 'form-group--error': $v.payload.email.$error }" v-model="$v.payload.email.$model"
                :state="$v.payload.email.$dirty ?
                  !$v.payload.email.$error : null" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estado civil*">
              <b-form-select :class="{ 'form-group--error': $v.payload.marital_status.$error }"
                v-model="$v.payload.marital_status.$model" :state="$v.payload.marital_status.$dirty ?
                  !$v.payload.marital_status.$error : null" :options="maritalOptions" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Valor do patrimônio*">
              <money class="form-control" :class="{ 'form-group--error': $v.payload.patrimony_value.$error }"
                v-model="$v.payload.patrimony_value.$model" :state="$v.payload.patrimony_value.$dirty ?
                  !$v.payload.patrimony_value.$error : null" v-bind="money" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Situação do imóvel*">
              <b-form-select :class="{ 'form-group--error': $v.payload.property_situation.$error }"
                v-model="$v.payload.property_situation.$model" :state="$v.payload.property_situation.$dirty ?
                  !$v.payload.property_situation.$error : null" :options="propertySituationOptions" />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <div class="border" v-if="payload.photo">
              <img :src="payload.photo" />
            </div>
            <b-form-group label="Documento (RG/CNH)*">
              <b-form-file @change="uploadFile" placeholder="Escolha um arquivo ou arraste-o aqui..."
                drop-placeholder="Solte o arquivo aqui..." browse-text="Procurar" />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <div class="border" v-if="payload.proof_of_address">
              <img :src="payload.proof_of_address" />
            </div>
            <b-form-group label="Comprovante de residência*">
              <b-form-file @change="uploadFile" placeholder="Escolha um arquivo ou arraste-o aqui..."
                drop-placeholder="Solte o arquivo aqui..." browse-text="Procurar" />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Salvar dados -->
        <div class="w-100 text-right mt-5">
          <b-button variant="success" @click="save()">Salvar</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { Money } from 'v-money';
import { required } from 'vuelidate/lib/validators';
import Autocomplete from 'vuejs-auto-complete';
import ClientService from '@/Services/ClientService';
import { profissoes } from '@/assets/professions.json';
import UploadService from '@/Services/UploadService';
import FinancingService from '@/Services/FinancingService';
import MessagesMixin from '@/mixins/messages-mixin';

export default {
  components: { Money, Autocomplete },
  mixins: [MessagesMixin],
  props: ['client_id'],
  data() {
    return {
      legalPerson: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      client: {},
      payload: {
        // campos similares
        property_situation: null,
        company_phone: '',
        marital_status: null,
        patrimony_value: '',
        client_id: this.client_id,
        // pessoa fisica
        birth_date: '',
        profession: '',
        monthly_income: '',
        company_time: '',
        bank_statement: '',
        occupation: '',
        lack: '',
        installments: '',
        // pessoa juridica
        cnpj_card: '',
        social_contract: '',
        installation_location: '',
        invoicing: '',
        email: '',
        photo: '',
        proof_of_address: '',
      },
      propertySituationOptions: [
        {
          text: 'Selecione',
          value: null,
        },
        {
          text: 'Próprio',
          value: 'proprio',
        },
        {
          text: 'Financiado',
          value: 'financiado',
        },
        {
          text: 'Alugado',
          value: 'alugado',
        },
        {
          text: 'Cedido',
          value: 'cedido',
        },
      ],
      maritalOptions: [
        {
          text: 'Selecione',
          value: null,
        },
        {
          text: 'Solteiro',
          value: 'solteiro',
        },
        {
          text: 'Casado(a)',
          value: 'casado',
        },
        {
          text: 'Divorciado(a)',
          value: 'divorciado',
        },
        {
          text: 'Viúvo',
          value: 'viuvo',
        },
      ],
      ocupationOptions: [
        {
          text: 'Selecione',
          value: null,
        },
        {
          text: 'Assalariado(a)',
          value: 'assalariado',
        },
        {
          text: 'Autônomo',
          value: 'autonomo',
        },
        {
          text: 'Aposentado',
          value: 'aposentado',
        },
        {
          text: 'Pensionista',
          value: 'pensionista',
        },
        {
          text: 'Empresário(a)',
          value: 'empresario',
        },
        {
          text: 'Funcionário(a) público(a)',
          value: 'funcionario_publico',
        },
        {
          text: 'Funcionário(a) privado(a)',
          value: 'funcionario_privado',
        },
        {
          text: 'Rural',
          value: 'rural',
        },
      ],
    };
  },
  computed: {
    professions() {
      return profissoes.map((item) => ({
        name: item,
        value: item,
      }));
    },
  },
  validations: {
    payload: {
      // pessoa fisica
      marital_status: {
        required,
      },
      patrimony_value: {
        required,
      },
      birth_date: {
        required,
      },
      profession: {
        required,
      },
      property_situation: {
        required,
      },
      monthly_income: {
        required,
      },
      company_phone: {
        required,
      },
      company_time: {
        required,
      },
      occupation: {
        required,
      },
      lack: {
        required,
      },
      installments: {
        required,
      },
      // pessoa juridica
      cnpj_card: {
        required,
      },
      social_contract: {
        required,
      },
      invoicing: {
        required,
      },
      email: {
        required,
      },
      photo: {
        required,
      },
      proofOfAddress: {
        required,
      },
    },
  },
  methods: {
    async uploadFile({ target }) {
      const formData = new FormData();
      formData.append('file', target.files[0]);
      const { data } = await UploadService.send(formData);
      this.payload.bank_statement = data;
    },

    async getClient() {
      const { data } = await ClientService.get(this.client_id);
      this.client = data;
    },

    onProfessionChange(value) {
      this.payload.profession = value.selectedObject.value;
    },

    async getFinancing() {
      const { data } = await FinancingService.getByClient(this.client_id);
      if (data) {
        this.payload = data;
      }
    },

    async save() {
      this.payload.client_id = this.client_id;
      if (this.payload.id) {
        await FinancingService.edit(this.payload, this.payload.id);
        this.messageSuccess('Financiamento atualizado com sucesso');
      } else {
        await FinancingService.save(this.payload, this.client_id);
        this.messageSuccess('Financiamento cadastrado com sucesso');
      }

      this.$router.push({ name: 'financings' });
    },
  },

  created() {
    this.getClient();
    this.getFinancing();
  },
};
</script>
